<template>
    <div class="new_content">
        <div class="cont_heard flex_bet_cen">
            <Button @click="goAdd()"   style="background:#4877E8;color:#fff;border-color:#4877E8">新增</Button>
        </div>
        <div class="cont_tab">
            <Table :columns="columns12" :data="data6" @on-selection-change="chooseData">
                <template slot-scope="{ index }" slot="index">
                    <strong>{{ index+1 }}</strong>
                </template>
                <template slot-scope="{ row }" slot="user_pic">
                    <div class="user_pic_box"><img :src="row.avatar_url" alt=""></div>
                </template>
                <template slot-scope="{ row }" slot="accountStatus">
                    <i-switch  v-model='row.status'  :true-value="1" :false-value="0" size="large" @on-change="changeStatus($event,row)">
                        <span slot="open">开启</span>
                        <span slot="close">禁用</span>
                    </i-switch>
                </template>    
                <template slot-scope="{ row }" slot="purview_id">
                <div v-if="row.purview_id">{{ getPurviewName(row.purview_id) }}</div>
                </template>
                <template slot-scope="{ row }" slot="action">
                    <Button @click="seeBtn(row)" style="color:#3C90EE;background: unset;" type="text">修改</Button>
                    <Button @click="del_use(row)" style="color:#12B130;background: unset;" type="text">删除</Button>
                </template>
            </Table>
            <br />
            <Page :total="total" :current="pageForm.page" @on-change="pageChange" show-total show-elevator style="text-align:right" />
        </div>
        <!-- 查看弹框 -->
        <Modal v-model="seemodal" :mask-closable="false" width="600">
            <p slot="header" style="text-align:center">{{ seetitle=='add'?'新增账户':'修改账户' }}</p>
            <div class="modal_contene">
                <Form label-position="right" :label-width="150">                   
                    <FormItem label="账号：" style="margin-top:12px">
                        <Input v-model="formData.account" style="width:300px" placeholder="请输入账户"></Input>
                    </FormItem>
                    <FormItem label="密码：">
                        <Input v-model="formData.pwd" style="width:300px" type="password" :placeholder="seetitle=='add'?'请输入密码':'请输入密码(不输入则表示密码不修改)'"></Input>
                    </FormItem>
                    <!-- <FormItem label="系统权限：">
                        <Select transfer v-model="purview_id_list" multiple placeholder="请选择系统权限" style="width:300px">
                            <Option v-for="item in goodsList" :value="item.id" :key="item.id">{{ item.name }}</Option>
                        </Select>
                    </FormItem>                   -->
                    <!-- <FormItem label="状态：" v-if="seetitle=='add'">
                        <i-switch  v-model="formData.status"  :true-value="1" :false-value="0" size="large">
                            <span slot="open">开启</span>
                            <span slot="close">禁用</span>
                        </i-switch>
                    </FormItem>                    -->
                </Form>               
            </div>
            <div slot="footer">
                <Button @click="seemodal = false">取消</Button>
                <Button @click="addBtn" style="color:#fff" class="btn">确定</Button>
            </div>
        </Modal>
    </div>
</template>

<script>
    import { get_user_list,edit_user_info,get_goods_list,del_user } from "@/api/index";
    export default {
        data(){
            return{
                purview_id_list:[],
                total: 0,
                pageForm:{
                    page:1,
                    limit:10,
                },
                goodsList: [],
                formData:{
                    account:'',
                    pwd:'',
                    purview_id:'',
                    status:0,//0禁用 1启用
                    id:''
                },
                seetitle: 'add',
                seemodal: false,
                columns12: [
                    // {type: 'selection',width: 60,align: 'center'},
                    {title: '序号',slot: 'index',align: 'center',},
                    {title: '账户',key: 'account',align: 'center',},
                    // {title: '系统权限',slot: 'purview_id',align: 'center',},
                    // {title: '禁用',slot: 'accountStatus',align: 'center',},                    
                    {title: '操作',slot: 'action',width: 220,fixed: 'right',align: 'center',}
                ],
                data6: [],
                platformIds:[],
            }
        },
        created(){
            this.getList()
            // this.get_system_list()
        },
        methods:{
            chooseData(e){
                console.log('e',e)
            },
            getPurviewName(ids) {
                const chooseIds = ids.split(",").map(id => Number(id));
                const names = this.goodsList
                    .filter(item => chooseIds.includes(item.id))
                    .map(item => item.name);
                return names.join("、")||[];
            },
            
            changeStatus(e,row){  
                let data =  {}
                data.account = row.account
                data.pwd = ''
                data.purview_id = row.purview_id
                data.status = e
                data.id = row.id               
                edit_user_info(data).then(res=>{
                    this.$Message.success(res.msg)
                    this.getList()
                }).catch(err=>{
                    this.getList()
                    this.$Message.error(err.msg)
                })                                   
            },
            get_system_list(){
                return
                get_platform_list(this.pageForm).then(res=>{
                    // this.platformDataList = res.data.data
                    this.goodsList = []
                    this.platformIds = [] 
                    res.data.data.forEach(item=>{
                        this.platformIds.push(item.application_id)
                    })
                    this.getPlatform()
                    
                }).catch(err=>{
                    this.goodsList = []
                    this.platformIds = [] 
                })


               
            },
            getPlatform(){
                get_system_list().then(res=>{
                    // this.goodsList = res.data
                    this.goodsList = []
                    res.data.forEach(item=>{
                        if(item.id&&this.platformIds.indexOf(item.id)!=-1){
                            this.goodsList.push(item)
                        }
                    })
                })
            },
            getList(){
                get_user_list(this.pageForm).then(res=>{
                    this.total = res.data.total
                    this.data6 = res.data.data
                })
            },
            goAdd(){              
                this.formData.account = ''
                this.formData.pwd = ''
                this.formData.purview_id = 1
                this.formData.status = 0
                this.formData.id = ''
                this.purview_id_list = []
                this.seetitle = 'add'
                this.seemodal = true
            },
            seeBtn(row){
                this.formData.account = row.account
                this.formData.pwd = ''
                this.formData.purview_id = row.purview_id
                this.formData.status = row.status
                this.formData.id = row.id
                this.purview_id_list = []
                let data = []
                if(row.purview_id){
                    data = row.purview_id.split(",")   
                    data.forEach(item => {
                        this.purview_id_list.push(item*1)
                    })  
                }                           
                this.seetitle = 'edit'
                this.seemodal = true
            },
            addBtn(){ 
                if(!this.formData.account){
                    this.$Message.error('请输入账户')
                    return
                }
                if(!this.formData.pwd && this.seetitle=='add'){
                    this.$Message.error('请输入密码')
                    return
                }
                // if(!this.purview_id_list.length){
                //     this.$Message.error('请选择系统权限')
                //     return
                // }
                if(this.seetitle=='add'){                    
                    // this.formData.purview_id=this.purview_id_list.join()
                    edit_user_info(this.formData).then(res=>{
                        this.$Message.success(res.msg)
                        this.getList()
                        this.seemodal = false
                    }).catch(err=>{
                        this.$Message.error(err.msg)
                    })
                }else {
                    this.formData.purview_id=this.purview_id_list.join()
                    edit_user_info(this.formData).then(res=>{
                        this.$Message.success(res.msg)
                        this.getList()
                        this.seemodal = false
                    }).catch(err=>{
                        this.$Message.error(err.msg)
                    })
                }               
            },
            del_use(e){
                this.$Modal.confirm({
                    title: '提示',
                    content: `是否确认删除`,
                    onOk: () => {
                        del_user({id:e.id}).then(res=>{
                            this.$Message.success(res.msg)
                            this.getList()
                        }).catch(err=>{
                            this.$Message.error(err.msg)
                            this.getList()
                        })
                    },
                    onCancel: () => {
                    }
                });
            },            
            pageChange(e){
                this.pageForm.page = e
                this.getList()
            }
        }
    }
</script>

<style scoped>
    .content{
        width: 100%;
        height: calc(100vh - 104px); 
        overflow: auto;
        padding: 20px 40px;
        background: #FFF;
        border-radius: 8px;
        padding-top: 50px;
        box-shadow: #F4F0E8 0px 1px 2px 0px;
    }
    .new_content {
        width: 100%;
        height: calc(100vh - 104px);
        overflow: auto;
        /* padding: 20px 40px; */
        /* background: #FFF; */
        border-radius: 8px;
        box-shadow: #F4F0E8 0px 1px 2px 0px;
    }
    .cont_heard {
        padding: 20px 40px;
        background: #FFF;
        min-height: 100px;
    }
    .cont_tab{
        margin-top: 20px;
        border-radius: 8px;
        padding: 20px 40px;
        min-height: calc(100% - 120px);
        background: #FFF;
    }
    
    .modal_contene{
        min-height: 280px;
        overflow: auto;
    }
    .user_pic_box{
        margin: 10px 0;
        width: 70px;
        height: 70px;
    }
    .user_pic_box img{
        width: 100%;
        height: 100%;
        border-radius: 10px;
        object-fit: cover;
    }    
    .btn{
        background: #4877E8;
    }    
</style>
<style>
    .ivu-table-header thead tr th {
        background: #fff !important;
    }
    .ivu-table-fixed-header thead tr th{
        background: #fff !important;
    }
    .ivu-table td, .ivu-table th {
        border-bottom: 1px solid #F0F6FC;
        color: #74798C;
    }
    .ivu-table:before{
        background: unset;
    }
    .ivu-modal-content{
        overflow: hidden;
    }
    .ivu-modal-header{
        background: #fff;
    }
    .ivu-modal-close .ivu-icon-ios-close{
        color: #0A1629;
    }
    .ivu-modal-footer{
        border-top: unset;
    }
</style>